export const patrikaData = [
  {
    month: "September",
    year: "2024",
    pdf: "sep-2024",
  },
  {
    month: "August",
    year: "2024",
    pdf: "aug-2024",
  },
  {
    month: "July",
    year: "2024",
    pdf: "jul-2024",
  },
  {
    month: "June",
    year: "2024",
    pdf: "jun-2024",
  },
  {
    month: "May",
    year: "2024",
    pdf: "may-2024",
  },
  {
    month: "April",
    year: "2024",
    pdf: "apr-2024",
  },
  {
    month: "March",
    year: "2024",
    pdf: "mar-2024",
  },
  {
    month: "February",
    year: "2024",
    pdf: "feb-2024",
  },
  {
    month: "January",
    year: "2024",
    pdf: "jan-2024",
  },
  {
    month: "December",
    year: "2023",
    pdf: "dec-2023",
  },
  {
    month: "November",
    year: "2023",
    pdf: "nov-2023",
  },
  {
    month: "October",
    year: "2023",
    pdf: "oct-2023",
  },
  {
    month: "September",
    year: "2023",
    pdf: "sep-2023",
  },
  {
    month: "August",
    year: "2023",
    pdf: "aug-2023",
  },
  {
    month: "July",
    year: "2023",
    pdf: "jul-2023",
  },
  {
    month: "June",
    year: "2023",
    pdf: "jun-2023",
  },
  {
    month: "May",
    year: "2023",
    pdf: "may-2023",
  },
  {
    month: "April",
    year: "2023",
    pdf: "apr-2023",
  },
  {
    month: "March",
    year: "2023",
    pdf: "mar-2023",
  },
  {
    month: "February",
    year: "2023",
    pdf: "feb-2023",
  },
  {
    month: "January",
    year: "2023",
    pdf: "jan-2023",
  },
  {
    month: "December",
    year: "2022",
    pdf: "dec-2022",
  },
];
